<template>
  <div>
    <v-card class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">Luo Vuokranet-tili</v-card-title>
      <p style="color: green; text-decoration: underline">
        <strong>Maksuton 30 päivän kokeilu!</strong>
      </p>
      <p class="mt-2">
        <strong
          >Rekisteröityminen on tarkoitettu vain vuokranantajille ja
          kiinteistösijoittajille!</strong
        >
      </p>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-checkbox
            v-model="investor"
            label="Olen sijoittaja tai vuokranantaja"
            hide-details
          ></v-checkbox>

          <v-checkbox v-model="user.isCompany" label="Käytän Vuokranettiä yrityksenä"></v-checkbox>

          <v-text-field
            v-if="user.isCompany"
            v-model="user.companyName"
            label="Yrityksen nimi"
            :rules="required"
            outlined
          ></v-text-field>

          <v-text-field
            v-if="user.isCompany"
            v-model="user.businessId"
            label="Yrityksen Y-tunnus"
            :rules="required"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="user.name"
            label="Etu- ja sukunimi"
            outlined
            :rules="required"
          ></v-text-field>

          <v-text-field
            v-model="user.email"
            label="Sähköpostiosoite"
            outlined
            :rules="emailRules.concat(required)"
            @keyup.enter="login"
          ></v-text-field>

          <v-text-field
            v-model="user.phone"
            outlined
            label="Puhelin"
            :rules="required"
          ></v-text-field>

          <v-text-field
            v-model="user.password"
            label="Salasana"
            type="password"
            outlined
            :rules="required.concat(passwordLength)"
          ></v-text-field>

          <v-text-field
            v-model="user.passwordConfirm"
            label="Salasana uudelleen"
            type="password"
            outlined
            :rules="required.concat(passwordLength)"
          ></v-text-field>

          <v-btn
            :disabled="completeMessage != '' || loading || !investor"
            block
            x-large
            color="primary"
            @click.prevent="register"
            >Rekisteröidy</v-btn
          >

          <p style="color: green; font-size: 18px" class="mt-4">
            {{ completeMessage }}
          </p>

          <div v-if="loading" class="mt-4">
            <h3 class="mb-1">Odota...</h3>
            <v-progress-circular
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

          <div v-if="!completeMessage" class="mt-5">
            <router-link to="/login"> Minulla on jo tili </router-link>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  title: "Rekisteröidy käyttäjäksi",

  mixins: [mixins],

  data(vm) {
    return {
      investor: false,
      user: {
        isCompany: false,
        name: "",
        companyName: "",
        businessId: "",
        phone: "",
        email: "",
        password: "",
      },
      loading: false,
      completeMessage: "",
      required: [(v) => !!v || "Pakollinen kenttä"],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      passwordLength: [(v) => vm.checkMinLength(v) || "Salasana on liian lyhyt."],
    };
  },

  created() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/overview");
    }
  },

  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        // test password
        if (this.user.password !== this.user.passwordConfirm) {
          this.showPopup("Salasanat eivät ole yhteneväiset", "error");
          return;
        }

        if (this.loading) return;

        try {
          this.loading = true;
          await this.$nextTick();

          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded();

          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha("login");

          const { data } = await axiosMethods.post("/api/v1/user/signup/", {
            user: this.user,
            token,
          });

          if (data.status === "success") {
            this.completeMessage =
              "Rekisteröinti onnistui. Saat vahvistusviestin antamaasi sähköpostiin. Tilin vahvistus tulee tehdä 24 tunnin sisällä.";
            this.$refs.form.reset();
          } else {
            this.showPopup(
              "Rekisteröinti ei onnistunut. Kokeile uudelleen tai ota yhteyttää ylläpitoon.",
              "error"
            );
          }

          this.loading = false;
        } catch (err) {
          this.showPopup(err, "error");
          this.loading = false;
        }
      } else {
        this.showPopup("Täytä vaaditut kentät", "error");
        this.loading = false;
      }
    },

    checkMinLength(password) {
      if (password) {
        return password.length > 6;
      } else {
        false;
      }
    },
  },
};
</script>

<style scoped></style>
